// Generated by Framer (c563d2c)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-PQEF6"

const variantClassNames = {Fe0jM3NOS: "framer-v-3xr2x4"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "Fe0jM3NOS", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-3xr2x4", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Fe0jM3NOS"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-228b5fa0-96b3-4b15-8bc3-528c090ea1ba, rgb(21, 21, 21))", borderBottomLeftRadius: 12, borderTopRightRadius: 12, ...style}}><SVG className={"framer-1w5fdcx"} layout={"position"} layoutDependency={layoutDependency} layoutId={"or22jKx3y"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 80 80\"><path d=\"M 0 0 L 80 80 L 12 80 C 5.373 80 0 74.627 0 68 Z\" fill=\"rgb(221, 221, 221)\"></path></svg>"} svgContentId={11062500699} withExternalLayout/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-PQEF6.framer-14e0fbz, .framer-PQEF6 .framer-14e0fbz { display: block; }", ".framer-PQEF6.framer-3xr2x4 { height: 80px; overflow: hidden; position: relative; width: 80px; will-change: var(--framer-will-change-override, transform); }", ".framer-PQEF6 .framer-1w5fdcx { flex: none; height: 80px; left: calc(50.00000000000002% - 80px / 2); position: absolute; top: calc(50.00000000000002% - 80px / 2); width: 80px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 80
 * @framerIntrinsicWidth 80
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerVQ1zuICOo: React.ComponentType<Props> = withCSS(Component, css, "framer-PQEF6") as typeof Component;
export default FramerVQ1zuICOo;

FramerVQ1zuICOo.displayName = "Component/Flipper";

FramerVQ1zuICOo.defaultProps = {height: 80, width: 80};

addFonts(FramerVQ1zuICOo, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})